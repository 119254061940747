<template>
    <div class="l-stack l-gap-1">
        <div :class="['form-label l-inline', { required: required }]">
            {{ label }}
        </div>
        <slot name="error"></slot>

        <info-box v-if="infoText">
            {{ infoText }}
        </info-box>

        <div
            v-for="(v, index) in inputValue"
            :key="index"
            class="l-inline l-center l-gap-2"
        >
            <input
                v-model="v.value"
                class="l-full-width"
                type="text"
                v-bind="$attrs"
                @blur="$emit('blur')"
                @input="dataChange"
            />
            <div>
                <icon-button @click="removeInput(index)">
                    <remove-icon width="24" height="24"></remove-icon>
                </icon-button>
            </div>
        </div>

        <div class="l-inline l-center l-gap-1 l-justify-end">
            <icon-button @click="inputValue.push({ value: '' })">
                <add-icon width="24" height="24"></add-icon>
            </icon-button>
        </div>
    </div>
</template>

<script>
import RemoveIcon from './icons/RemoveIcon'
import AddIcon from './icons/AddIcon'
import IconButton from './IconButton'
import InfoBox from './InfoBox'

export default {
    components: {
        InfoBox,
        RemoveIcon,
        AddIcon,
        IconButton,
    },
    // Disable automatic attribute inheritance, so that $attrs are
    // passed to the <input>, even if it's not the root element.
    // https://vuejs.org/v2/guide/components-props.html#Disabling-Attribute-Inheritance
    inheritAttrs: false,
    props: {
        required: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        infoText: {
            type: String,
            default: '',
        },
        value: {
            type: Array,
            default: () => [''],
        },
    },
    data() {
        let iv = [{ value: '' }]
        if (this.value && this.value.length > 0) {
            iv = this.value.map(value => {
                return { value: value }
            })
        }
        return {
            inputValue: iv,
            showInfoBox: false,
        }
    },
    methods: {
        removeInput(index) {
            this.inputValue.splice(index, 1)

            if (this.inputValue.length == 0) {
                this.inputValue.push({ value: '' })
            }

            this.dataChange()
        },
        dataChange() {
            let data = this.inputValue
                .filter(v => v.value.length > 0)
                .map(v => v.value)

            this.$emit('input', data)
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "requiredError": "this field can't be empty."
        },
        "de": {
            "requiredError": "Dieses Feld darf nicht leer sein."
        },
		"it": {
            "requiredError": "Questo Feld darf nicht leer sein."
        }
    }
</i18n>

<style scoped lang="scss"></style>
